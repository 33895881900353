import { Component, ElementRef } from '@angular/core';

import { DatePipe } from '@angular/common';
import { ManagedataService } from './services/manage-data.service';
import { SharedserviceService } from './services';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [DatePipe]
})
export class AppComponent {
  title = 'fareboutique';
  message: any = null;
  browser: any;

  constructor(private el: ElementRef, public manageService: ManagedataService, public datePipe: DatePipe,public sharedService:SharedserviceService) {
   // console.log("App Component>>>>>",window.location.pathname)
    if(window.location.pathname == '/inventory'){
      this.getInventoryList()
    }

  }
  getInventoryList(){
    var result = [];
    for (var i = 0; i < 7; i++) { var d = new Date(); d.setDate(d.getDate() + i); result.push(this.datePipe.transform(new Date(d), 'd MMM')); }
    var resultdate = [];
    for (var i = 0; i < 7; i++) {
      var d = new Date();
      d.setDate(d.getDate() + i);
      resultdate.push(this.datePipe.transform(new Date(d), 'yyyy-MM-dd'));
    };
    var isCalenderKeys=result;
    this.sharedService.post('v1/flight/inventory',{"show_all":false}).subscribe((data:any)=>{
      if(data.replyCode == "success"){
        var exportInventoryData=data.data;
        var isCalenderListResponse = [];
        const key = 'id';
        var arrayUniqueByKey:any = [...new Map((data.data).map(item =>[item[key], item])).values()];
        if ((data.ids).length != 0) {
          (data.ids).forEach(ele => {
            var inv_data = arrayUniqueByKey.filter(x => x.flight_id == ele.id);
            inv_data.sort(function (a, b) {
              return new Date(a.date).getTime() - new Date(b.date).getTime()
            })
            if (inv_data.length > 0) {
              var element = {"flight_id": inv_data[0].flight_id,"dep_city_code": inv_data[0].dep_city_code,"departure_date": inv_data[0].date,
                "date": inv_data.date,"arrival_date": inv_data[inv_data.length - 1].date,"name": inv_data[0].name,"supplier_user_name": inv_data[0].supplier_user_name,"return_flight": inv_data[0].return_flight,"status": inv_data[0].status,"flight_pnrs": inv_data,"id": "","sevenlist": [],"mindate": "","maxdate": "","disablecal": false,"event": []}
              isCalenderListResponse.push(element);
              isCalenderListResponse = isCalenderListResponse.sort((a, b) => (a.name < b.name) ? -1 : 1)
              var cal_event = element;
              var calender_list = cal_event.flight_pnrs;
              var events = [];
              var sevendaydata = cal_event.flight_pnrs;
              let s_day = [];
              resultdate.forEach(elementDate => {
                var key = elementDate;
                let svnDaat = sevendaydata.find(el => el.date === key);
                if (svnDaat == undefined) {
                  s_day.push({ seat: 'N/A' });
                } else {
                  s_day.push({seat: svnDaat.available_seats,blockseat: svnDaat.block_seat,date: svnDaat.date,pnr: svnDaat.pnr_number,price: svnDaat.price});
                }
              });
              element.sevenlist = s_day;
              var startdate: any = element.departure_date; var enddate: any = element.arrival_date;
              var currentdate: any = new Date();
              if (new Date(startdate) > currentdate) {
                element.mindate = startdate;
                element.maxdate = enddate; element.disablecal = false;
              } else {
                if ((new Date(startdate) < currentdate) && (new Date(enddate) < currentdate)) {
                  element.mindate = startdate; element.maxdate = enddate;
                  element.disablecal = true;
                } else {
                  element.mindate = this.transformDate(currentdate)
                  element.maxdate = enddate; element.disablecal = false;
                }
              }
              calender_list.forEach(element => {
                if (element.block_seat != 0 && element.block_seat != null) {
                  events.push(
                    { id: element.date, title: '' + element.available_seats, start: element.date, end: element.date, name: element.date, editable: false },
                    { id: element.date, title: 'PNR: ' + element.pnr_number, start: element.date, end: element.date, name: element.date, className: 'myOption', editable: false },
                    { id: element.date, title: 'Rs.: ' + element.price, start: element.date, end: element.date, name: element.date, className: 'myOptionDown', editable: false },
                    { id: element.date, title: '' + element.block_seat, start: element.date, end: element.date,
                      name: element.date, className: 'box-size', editable: false}
                  )
                }else if (element.available_seats == 0) {
                  events.push(
                    {
                      id: element.date, title: ' ' + element.available_seats, start: element.date, end: element.date,
                      name: element.date, className: 'fc-day-grid-event1 ', editable: false
                    },
                    { id: element.date, title: 'PNR: ' + element.pnr_number, start: element.date, end: element.date, name: element.date, className: 'myOption', editable: false },
                    { id: element.date, title: 'Rs.: ' + element.price, start: element.date, end: element.date, name: element.date, className: 'myOptionDown', editable: false },
                  )
                }else {
                  events.push({ id: element.date, title: element.available_seats, start: element.date, end: element.date, name: element.date, },
                    { id: element.date, title: 'PNR: ' + element.pnr_number, start: element.date, end: element.date, name: element.date, className: 'myOption', editable: false },
                    { id: element.date, title: 'Rs.: ' + element.price, start: element.date, end: element.date, name: element.date, className: 'myOptionDown', editable: false },
                  )
                }
                var startdate: any = cal_event.departure_date; var enddate: any = cal_event.arrival_date; var currentdate: any = new Date();
                if (new Date(startdate) > currentdate) { cal_event.mindate = startdate; cal_event.maxdate = enddate; cal_event.disablecal = false; }
                else {
                  if (new Date(enddate) > currentdate) { cal_event.mindate = this.transformDate(currentdate); cal_event.maxdate = enddate; cal_event.disablecal = false; }
                  else { cal_event.mindate = startdate; cal_event.maxdate = enddate; cal_event.disablecal = true; }
                }
              });
              cal_event.event = events;
            }
          });
          // if (isCalenderListResponse.length >= 1) {
            isCalenderListResponse = isCalenderListResponse.sort((a, b) => (a.name < b.name) ? -1 : 1);
            this.manageService.SetInventoryCalenderData({isCalenderListResponse:isCalenderListResponse,exportInventoryData:exportInventoryData,isCalenderKeys:isCalenderKeys})
          // }
        }
      }
    })
  }

  transformDate(date) { return this.datePipe.transform(date, 'yyyy-MM-dd'); }
  ngOnInit(): void {
    this.browser = this.myBrowser();
    if(localStorage.getItem('user') != undefined && localStorage.getItem('user') != null && localStorage.getItem('user') != "" ){
      var userInfo:any=localStorage.getItem('user');
      this.manageService.SetLoggedInUserDetails(JSON.parse(userInfo));
      this.manageService.SetLoggedInUserImage(JSON.parse(userInfo).image);
    }
    if(localStorage.getItem('user_image_url') != undefined && localStorage.getItem('user_image_url') != null && localStorage.getItem('user_image_url') != "" ){
      var user_image_url:any=localStorage.getItem('user_image_url');
      this.manageService.SetLoggedInUserImageUrl(JSON.parse(user_image_url));
    }
    if(sessionStorage.getItem('inventory_viewtype') != undefined && sessionStorage.getItem('inventory_viewtype') != null && sessionStorage.getItem('inventory_viewtype') != "" ){
      var inventory_viewtype:any=sessionStorage.getItem('inventory_viewtype');
      this.manageService.SetInventoryViewType(inventory_viewtype);
    }
    if(localStorage.getItem('isHotelTravellersInfo') != undefined && localStorage.getItem('isHotelTravellersInfo') != null && localStorage.getItem('isHotelTravellersInfo') != "" ){
      var isHotelTravellersInfo:any=localStorage.getItem('isHotelTravellersInfo');
      this.manageService.SetHotelTravellersInfo({
        numberOfAdults:JSON.parse(isHotelTravellersInfo).numberOfAdults,
        numberOfChild:JSON.parse(isHotelTravellersInfo).numberOfChild,
        start_date: JSON.parse(isHotelTravellersInfo).start_date,
        end_date: JSON.parse(isHotelTravellersInfo).end_date,
        numberOfRooms:JSON.parse(isHotelTravellersInfo).numberOfRooms,
        bookingToken:JSON.parse(isHotelTravellersInfo).bookingToken || '',
        baseAmount:JSON.parse(isHotelTravellersInfo).baseAmount || '',
        tax:JSON.parse(isHotelTravellersInfo).tax || '',
        margin:JSON.parse(isHotelTravellersInfo).margin || '',
        totalAmount:JSON.parse(isHotelTravellersInfo).totalAmount || '',
        optionId:JSON.parse(isHotelTravellersInfo).optionId || '',
      });
    }
    if(localStorage.getItem('token') != undefined && localStorage.getItem('token') != null && localStorage.getItem('token') != "" ){
      var token:any=localStorage.getItem('token');
      this.manageService.SetUserToken(JSON.parse(token));
    }
  }

  myBrowser() {
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf("Chrome") != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf("Firefox") != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf("MSIE") != -1) {
      return 'IE';
    } else {
      return 'unknown';
    }
  }
  scroll$ = fromEvent(this.el.nativeElement, 'scroll')
}
