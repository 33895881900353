import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { CommonService } from './commonservice.service';

@Injectable({
	providedIn: 'root'
  })
export class TokenGuard implements CanActivate {
	public token:any;
	constructor(
		private commonService: CommonService, 
		private router: Router){}
	canActivate(): boolean {
		if(this.commonService.getToken()){
			return true;
		}
		return false;
    }
}   
