// export const environment = {
//   production: false,
//   apiUrl:'https://devapi.fareboutique.com/v1/',
//   url : 'https://devapi.fareboutique.com/',
//   url_booking_cancel : 'https://fabdev.fareboutique.com/',
//   hotel_booking_url : 'https://fd1.fareboutique.com/',
//   imageurl:'https://devapi.fareboutique.com/',
//   airlineurl:'https://devapi.fareboutique.com/',
//   adminPath:'',
//   key:'2PBP7IABZ2',
//   salt:'DAH88E3UWQ',
//   firebase: {
//     apiKey: "AIzaSyCYD9XULCFhZ85ITW2I_pH67ZxQG0Lh-6A",
//     authDomain: "angularnoti-5224a.firebaseapp.com",
//     projectId: "angularnoti-5224a",
//     storageBucket: "angularnoti-5224a.appspot.com",
//     messagingSenderId: "1015037219900",
//     appId: "1:1015037219900:web:ed895e3899d80ec256df39",
//     measurementId: "G-T1JKFBCXQ5",
//     vapidKey: 'BB0DjFt-0d_n8Pg3aEPnGjn3NRAkFrEzAAP7tQl0xLHZUbo9joPkvXmazhQUcRDIrkdxK3DlHS988deNwevgg6Y'
//   }
// };

export const environment = {
  production: false,
  apiUrl:'https://fd.fareboutique.com/v1/',
  url : 'https://fd.fareboutique.com/',
  url_booking_cancel : 'https://fabdev.fareboutique.com/',
  hotel_booking_url : 'https://api-erp.fareboutique.com/',
  // hotel_booking_url : 'https://fd1.fareboutique.com/',
  imageurl:'https://fd.fareboutique.com/uploads/userprofile/',
  airlineurl:'https://fd.fareboutique.com/uploads/airlines/',
  adminPath:'/admin',
  firebase: {
    apiKey: "AIzaSyCYD9XULCFhZ85ITW2I_pH67ZxQG0Lh-6A",
    authDomain: "angularnoti-5224a.firebaseapp.com",
    projectId: "angularnoti-5224a",
    storageBucket: "angularnoti-5224a.appspot.com",
    messagingSenderId: "1015037219900",
    appId: "1:1015037219900:web:ed895e3899d80ec256df39",
    measurementId: "G-T1JKFBCXQ5",
    vapidKey: 'BB0DjFt-0d_n8Pg3aEPnGjn3NRAkFrEzAAP7tQl0xLHZUbo9joPkvXmazhQUcRDIrkdxK3DlHS988deNwevgg6Y'
  },
  /* if we build for client use main domain change adminPath "/admin"
   otherwise blank adminPath use for localhost //and online use other domain change according to your folder path*/
};
