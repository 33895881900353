import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiRequestService } from 'src/app/services/api.service';
import { DateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { ManagedataService } from 'src/app/services/manage-data.service';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-flight-search',
  templateUrl: './flight-search.component.html',
  styleUrls: ['./flight-search.component.scss']
})
export class FlightSearchComponent implements OnInit{
  // Form Variable
  public searchForm: FormGroup | any; submitted = false; btnsubmitted: boolean = false;
  travellers: number=1; isTravelsObject:any={ adult:1, child:0, infant:0 };
  public isTripValue: any = 'one_way'; public isMasterOptions: any = '1';
  constructor( private formBuilder: FormBuilder, public datePipe: DatePipe, public router: Router,private dateAdapter: DateAdapter<Date>,public manageDataService:ManagedataService,public apiService: ApiRequestService) { this.dateAdapter.setLocale('en-GB'); }
  SelectedDepartureData:any={}; SelectedArrivalData:any={};
  departureDateInputFilter: any; mindepartureDate: Date = new Date(); maxdepartureDate: Date = new Date();
  arrivalDateInputFilter: any; minarrivalDate: Date = new Date(); maxarrivalDate: Date = new Date();
  isDateNextPreviousOptionFlag:boolean=false;isSelectedDateArray:any=[];
  currentDateStep: any = 0;to_day: any;
  isSelectedDate:any="";
  travellersForm: FormGroup;
  isLoggedInStatus:boolean=false;
  ngOnInit(): void {
    this.manageDataService.GetLoggedInUserDetails().subscribe(data =>{
      console.log("LOgged",data)
      if(Object.keys(data).length>0){
        this.apiService.onDepartureFlightList({ flight_type: this.isTripValue });
      }
    });
    this.searchForm = this.formBuilder.group({
      departure_city_id: ['', Validators.required], arrival_city_id: ['', Validators.required],
      departure_date: ['', Validators.required], return_date: [''],
      adult: ['1'], child: ['0'], travellers: [1], infant: ['0']
    });
    this.travellersForm = this.formBuilder.group({  adult: ['1'],  child: ['0'],  infant: ['0'],  });
    this.manageDataService.GetTripValue().subscribe((data: any) => {
      if (data) {
        this.isTripValue = data;
        if (this.isMasterOptions == 1) {
          this.apiService.onDepartureFlightList({ flight_type: this.isTripValue });
        }
      }
    })
    this.manageDataService.GetMasterTripOptions().subscribe((data: any) => {
      if (data) {
        this.isMasterOptions = data;
      }
    })
    this.manageDataService.GetSelectedTravelersData().subscribe((data: any) => {
      if (Object.keys(data).length > 0) {
        var count = 0;
        count = count + Number(data.adult) + Number(data.child) + Number(data.infant);
        this.searchForm.controls["travellers"].setValue(count);
      }
    })

    if(this.isLoggedInStatus){

    }

    this.manageDataService.GetSelectedDepartureData().subscribe((data: any) => {
      if (Object.keys(data).length > 0) {
        this.SelectedDepartureData = data;
        this.searchForm.controls["departure_city_id"].setValue(this.SelectedDepartureData.departure_city_id);
      }
    })
    this.manageDataService.GetSelectedArrivalData().subscribe((data: any) => {
      if (Object.keys(data).length > 0) {
        this.SelectedArrivalData = data;
        this.searchForm.controls["arrival_city_id"].setValue(this.SelectedArrivalData.arrival_city_id);
      }
    })
    this.manageDataService.GetDepartureDateArray().subscribe((data: any) => {
      if (data.length > 0) {
        let validdates: any = [];
        this.manageDataService.SetAvailableToDate(data)
        data.forEach((element: any) => {
          var dateObject: any = this.datePipe.transform(element.flight_date, 'MM/dd/yyyy');
          validdates.push(new Date(dateObject))
        });
        this.departureDateInputFilter = (d: Date): any => { if (d) { const time = d.getTime(); return validdates.find((x: any) => x.getTime() == time); } }
        this.mindepartureDate = data[0].flight_date;
        this.maxdepartureDate = data[data.length - 1].flight_date;
      }
    })
    this.manageDataService.GetAvailableToDate().subscribe((data)=>{
      if(data && data.length>0){
      this.isSelectedDateArray=data;
      }
    })
    this.manageDataService.GetAvailableCurrentDate().subscribe((data)=>{
      if(data){
        this.to_day=data
        this.currentDateStep = this.isSelectedDateArray.findIndex((e:any) => e.flight_date == data);
      }
    })
    this.manageDataService.GetReturnDateArray().subscribe((data: any) => {
      if (data.length > 0) {
        let validdates: any = [];
        data.forEach((element: any) => {
          var dateObject: any = this.datePipe.transform(element.flight_date, 'MM/dd/yyyy');
          validdates.push(new Date(dateObject))
        });
        this.arrivalDateInputFilter = (d: Date): boolean => { if (d) { const time = d.getTime(); return validdates.find((x: any) => x.getTime() == time); } else { return false } }
        this.minarrivalDate = data[0].flight_date;
        this.maxarrivalDate = data[data.length - 1].flight_date;
      }
    })
    this.manageDataService.GetRegularCityArray().subscribe((data:any)=>{
      if(data.length>0){
        this.manageDataService.SetDepartureArray(data)
      }
    })
    // this.onSearchApiRequest();
  }
  get f() { return this.searchForm.controls; }
  onSelectTrip(value: any) {
    this.manageDataService.SetSearchFlightResponse({});
    this.manageDataService.SetTripValue(value);
    this.manageDataService.SetSelectedDepartureData({});
    this.manageDataService.SetSelectedArrivalData({});
    this.searchForm.reset();
  }
  onSelectMenu(value: any) {
    this.manageDataService.SetSearchFlightResponse({});
    this.manageDataService.SetMasterTripOptions(value?2:1);
    this.manageDataService.SetSelectedDepartureData({});
    this.manageDataService.SetSelectedArrivalData({});
    this.searchForm.reset();
    if (value) {
      this.isTripValue = "one_way";
      this.onDepartureRegularList();
      this.searchForm.reset();
      this.searchForm.controls["travellers"].setValue(this.getTravellersCount());
    } else {
      this.isTripValue = "one_way";
      this.apiService.onDepartureFlightList({ flight_type: this.isTripValue });
      this.searchForm.reset();
      this.searchForm.controls["travellers"].setValue(this.getTravellersCount());
    }
  }
  getTravellersCount(){
    var value=0;
    value=value+Number(this.isTravelsObject.adult)+Number(this.isTravelsObject.child)+Number(this.isTravelsObject.infant);
    return value;
  }
  onDepartureRegularList() {
    this.apiService.onDepartureArrivalFlightList({ city_name: '' });
  }
  onSelectDepartureDate(date: any) {
    this.isSelectedDate = this.datePipe.transform(new Date(date), 'yyyy-MM-dd');
    this.manageDataService.SetAvailableCurrentDate(this.isSelectedDate);
    if (this.isTripValue == "return") {
      this.getReturnDateList(date);
      // console.log("check dateList=========>>>>>>",date);
    }
  }
  getReturnDateList(departureDate:any){
    this.apiService.onArrivalFlightDateList({
      "departure_city_code":this.SelectedDepartureData.departure_city_code,
      "arrival_city_code":this.SelectedArrivalData.arrival_city_code,
      "departure_city_id":this.SelectedDepartureData.departure_city_id,
      "arrival_city_id":this.SelectedArrivalData.arrival_city_id,
      "is_return":this.isTripValue=="one_way"?0:1,
      "departure_date": this.datePipe.transform(departureDate, 'yyyy-MM-dd'),
    })
  }
  dateTransform(date:any){
    var dates = new Date(date);
    return this.datePipe.transform(dates, "dd-MM-yyyy")
  }
  onPrevious() {
    if (this.currentDateStep > 0){
      this.currentDateStep--;
    }
    this.isSelectedDate=this.isSelectedDateArray[this.currentDateStep].flight_date;
    this.onSearchApiRequest();
  }
  onNext() {
    if (this.currentDateStep < this.isSelectedDateArray.length - 1){
      this.currentDateStep++;
    }
    this.isSelectedDate=this.isSelectedDateArray[this.currentDateStep].flight_date;
    this.onSearchApiRequest();;
  }
  setsearchdate(d:any) {
    // this.searchForm.patchValue({
    //   departure_date: this.datePipe.transform(d, "yyyy-MM-dd")
    // });
    // console.log("seaer",this.datePipe.transform(d,"yyyy-MM-dd"))
    // this.searchsubmit();
  }
  onSubmit(){
    this.submitted=true;
    if(this.searchForm.invalid){
      return;
    }else{
      var flightObject: any = {};
      if (this.searchForm.value.departure_date) {
        flightObject.departure_date = this.datePipe.transform(this.searchForm.value.departure_date, 'yyyy-MM-dd');
        this.searchForm.value.departure_date = this.isSelectedDate;
      }
      if (this.searchForm.value.return_date) {
        this.searchForm.value.return_date = this.datePipe.transform(this.searchForm.value.return_date, 'yyyy-MM-dd');
        flightObject.return_date = this.datePipe.transform(this.searchForm.value.return_date, 'yyyy-MM-dd')
      }
      this.searchForm.value.adult= this.isTravelsObject.adult;
      this.searchForm.value.child= this.isTravelsObject.child;
      this.searchForm.value.infant= this.isTravelsObject.infant;
      this.searchForm.value.travellers= this.isTravelsObject.travellers;
      flightObject.formValue = this.searchForm.value;
      flightObject.selectedDeparture = this.SelectedDepartureData;
      flightObject.selectedArrival = this.SelectedArrivalData;
      flightObject.isTripType = this.isTripValue;
      flightObject.isMasterType = this.isMasterOptions;
      sessionStorage.setItem("search_form_Data", JSON.stringify(flightObject));
      // this.commonService.SetArrivalChange(false);
      this.onSearchApiRequest();
    }
  }
  onSearchApiRequest(){
    var JsonData:any=sessionStorage.getItem('search_form_Data');
    var payloadObject:any={};
    this.searchForm.controls["departure_date"].setValue(this.isSelectedDate);
    payloadObject.dep_city_code=JSON.parse(JsonData).selectedDeparture.departure_city_code;
    payloadObject.dep_city_id=Number(JSON.parse(JsonData).selectedDeparture.departure_city_id);
    payloadObject.dep_airport_id=Number(JSON.parse(JsonData).selectedDeparture.departure_airport_id);
    payloadObject.departure_date=this.isSelectedDate;
    payloadObject.arr_city_code=JSON.parse(JsonData).selectedArrival.arrival_city_code;
    payloadObject.arr_city_id=Number(JSON.parse(JsonData).selectedArrival.arrival_city_id);
    payloadObject.arr_airport_id=Number(JSON.parse(JsonData).selectedArrival.arrival_airport_id);
    payloadObject.return_date=JSON.parse(JsonData).formValue.return_date;
    payloadObject.adult=Number(JSON.parse(JsonData).formValue.adult);
    payloadObject.children=Number(JSON.parse(JsonData).formValue.child);
    payloadObject.infant=Number(JSON.parse(JsonData).formValue.infant);
    payloadObject.trip_type=this.isTripValue == 'one_way'?0:1;
    payloadObject.travellers=this.getTravellersCount();
    if(this.isMasterOptions == 1){
      this.apiService.onCustomFlightSearch(payloadObject);
    }else{
      payloadObject.prefered_airline="AI";
      this.apiService.onRegularFlightSearch(payloadObject);
    }
    sessionStorage.setItem("search_form", JSON.stringify(payloadObject));
    this.isDateNextPreviousOptionFlag=true;
    // this.router.navigateByUrl("/search-result");
  }
  open_popup() {  $('#totalpassengers').modal('show');  }
  total: any;
  traveller() {
    var adult = this.travellersForm.value.adult;  var child = this.travellersForm.value.child;  var infant = this.travellersForm.value.infant;
    if (adult != '' && child != '' && infant != '') {
      if (parseInt(adult) >= parseInt(infant)) {
        this.total = parseInt(adult) + parseInt(child) + parseInt(infant);
        this.searchForm.patchValue({  adult: parseInt(adult),  child: parseInt(child),  infant: parseInt(infant)  });
        this.isTravelsObject.adult=adult;
        this.isTravelsObject.child=child;
        this.isTravelsObject.infant=infant;
        this.isTravelsObject.travellers=this.total;
        this.manageDataService.SetSelectedTravelersData(this.isTravelsObject);
        $('#totalpassengers').modal('hide');  this.searchForm.patchValue({ travellers: this.total });
      } else {  alert('Please select infant less than adult');  }
    } else {  alert('All Field is required');  }
  }
}
