import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiRequestService } from 'src/app/services/api.service';
import { DateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { ManagedataService } from 'src/app/services/manage-data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { Select2OptionData } from 'ng-select2';
import { HotelService, SharedserviceService } from 'src/app/services';
import { ToastrManager } from 'ng6-toastr-notifications';

declare var $: any;

@Component({ selector: 'app-dashboard', templateUrl: './dashboard.component.html', styleUrls: ['./dashboard.component.scss'], providers: [] })

export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  BasehotelData :any={};
  isLoggedInUser:any={};
  constructor(public changeDetector: ChangeDetectorRef, private formBuilder: FormBuilder, public datePipe: DatePipe, public router: Router, private dateAdapter: DateAdapter<Date>, public manageDataService: ManagedataService, public apiService: ApiRequestService,public baseService: SharedserviceService,private ngxLoader: NgxUiLoaderService, private toastr: ToastrManager,public sharedService:HotelService) { this.dateAdapter.setLocale('en-GB'); }

  ngOnInit(): void {
   
    this.manageDataService.GetLoggedInUserDetails().subscribe((response:any)=>{
      if(Object.keys(response).length>0){
        this.isLoggedInUser=response;
      }
    })
    this.userHotelDetails()
  }

  userHotelDetails(){
    var hotelDataReq={"user_id":this.isLoggedInUser.id}
    this.sharedService.post('user_hotels',hotelDataReq).subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        this.BasehotelData=res.data;     
        localStorage.setItem("BasehotelData", JSON.stringify(res.data));   
      }else{
        this.BasehotelData=[];
      }
    },err =>{
      this.toastr.errorToastr(err.replyMsg,'Error')
    })
  }

  ngAfterViewInit() {

  }
  ngOnDestroy() {

  }
}


