import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';

import { AdminlayoutModule } from './adminlayout/adminlayout.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppStoreModule } from './modal/app/app-store.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CheckoutModule } from 'paytm-blink-checkout-angular';
import { DashboardlayoutModule } from './dashboardlayout/dashboardlayout.module';
import { HomedashboardModule } from './homedashboard/homedashboard.module';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from './layout/layout.module';
import { MAT_DATE_LOCALE } from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MaterialModule } from './route-file/material.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { OrderByPipe } from './services/order-by.pipe';
import { RootStoreModule } from './root-store';
import { ToastrModule } from 'ng6-toastr-notifications';
import { SpeechSearchComponent } from './speech-search/speech-search.component';

@NgModule({
  declarations: [
    AppComponent,
    OrderByPipe,
    SpeechSearchComponent
  ],
  imports: [
    BrowserModule,BrowserAnimationsModule,
    FormsModule,ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    MatFormFieldModule,
    NgxUiLoaderModule,
    CheckoutModule,
    Ng2SearchPipeModule,
    RootStoreModule,
    AppStoreModule,
    DashboardlayoutModule,
    HomedashboardModule,
    AdminlayoutModule,
    LayoutModule,
    MaterialModule
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }, NgxUiLoaderService],
  bootstrap: [ AppComponent ],
  exports: [OrderByPipe, Ng2SearchPipeModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA]
})
export class AppModule { }
