import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { ApiRequestService } from 'src/app/services/api.service';
import { ManagedataService } from 'src/app/services/manage-data.service';
declare var jQuery: any;

@Component({
  selector: 'app-custom-departure-select-box',
  templateUrl: './custom-departure-select-box.component.html',
  styleUrls: ['./custom-departure-select-box.component.scss']
})
export class CustomDepartureSelectBoxComponent implements OnInit{
  public text: String;
  isDepartureSelectBoxFlag:boolean=false;
  public isTripValue: any = 'one_way';
  public isMasterOptions: any = '1';
  isDummyDepartureData:any=[];
  isDummyArrivalData:any=[];
  public options1: Options;

  public departureDataMaster: Array<Select2OptionData> | any; departureArrayMaster: any = []; departureArrayResponseMaster: any = [];departureArrayAllResponseMaster: any = []; departureArrayResponseStatus0: any = [];departureArrayResponseStatus1: any = []; SelectedDepartureDataMaster: any = {};
  // public arrivalData: Array<Select2OptionData> | any; public arrivalArray: any = []; arrivalArrayResponse: any = []; arrivalArrayResponseStatus0: any = []; arrivalArrayResponseStatus1: any = []; SelectedArrivalData: any = {};
  departureData: any = []; departureArray: any = [];departureArrayAllData: any = []; departureArrayResponse: any = []; SelectedDepartureData: any = {};
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.eRef.nativeElement.contains(event.target)) {
      this.text = "clicked inside";
    } else {
      this.manageDataService.SetDepartureSelectBox(false);
      this.text = "clicked outside";
    }
  }
  constructor(private eRef: ElementRef,public manageDataService:ManagedataService,public apiService: ApiRequestService){
  }
  ngOnInit(): void {
    this.manageDataService.GetDepartureSelectBox().subscribe((flag:boolean)=> this.isDepartureSelectBoxFlag=flag);
    this.manageDataService.GetTripValue().subscribe((data: any) => {
      if (data) {
        this.isTripValue = data;
      }
    })
    this.manageDataService.GetDepartureArray().subscribe((data: any) => {
      // console.log("data",data);
      this.departureArrayResponse = [];
      this.departureArrayResponseMaster = [];
      this.departureArray = [];
      if (data.length > 0) {
        if(this.isMasterOptions=='2'){
          this.departureArrayResponse = data;
          this.departureArrayAllData = JSON.stringify(data);
        }else{
          this.departureArrayResponseMaster = data;
          this.departureArrayAllResponseMaster = JSON.stringify(data);
          // console.log("departureArrayResponseMaster",this.departureArrayResponseMaster);
          this.departureArrayResponseMaster.forEach((element: any) => {
            var object:any={};
            object.id=element.departure_city_id;
            object.text='<span style="font-size:16px">' + element.departure_city_name + '<p style="display:none;">' + element.departure_city_code + '</p></span>',
            object.additional={
              image: '<span style="font-weight:500">(' + element.departure_city_code + ')</span>',
              winner: '<span style="font-weight:500;width:100%;float:lef">' + element.airport_name + '</span>'
            }
            // var object: any = {
            //   id: element.departure_city_id,
            //   text: element.departure_city_name + ' (' + element.departure_city_code + ')',
            //   status: element.status
            // };
            this.departureArray.push(object);
            this.departureDataMaster = this.departureArray;
          });
          this.options1 = {
            templateResult: this.templateResult,
            templateSelection: this.templateSelection
          };

        }
      }
    })
    this.manageDataService.GetSelectedDepartureData().subscribe((data: any) => {
      if (Object.keys(data).length > 0) {
        this.SelectedDepartureData = data;
        // console.log("SelectedDepartureData>>>>>>",this.SelectedDepartureData);
      }else{
        this.SelectedDepartureData = {};
      }
    })
    this.manageDataService.GetMasterTripOptions().subscribe((data: any) => {
      if (data) {
        this.isMasterOptions = data;
      }
    })
  }
  onOpenCloseDeparture(){
    this.isDepartureSelectBoxFlag=!this.isDepartureSelectBoxFlag;
    this.manageDataService.SetDepartureSelectBox(this.isDepartureSelectBoxFlag);
  }
  onSelectDepartureCity(departureObject:any){
    this.manageDataService.SetSelectedDepartureData(departureObject);
    this.manageDataService.SetSelectedArrivalData({})
    console.log("departureArrayAllData>>>>",JSON.parse(this.departureArrayAllData));
    this.departureArrayResponse=JSON.parse(this.departureArrayAllData);
    if (this.isMasterOptions == 2) {
      var datasssss:any=this.departureArrayResponse;
      this.isDummyArrivalData=datasssss;
      this.isDummyArrivalData.splice(this.isDummyArrivalData.findIndex((item: any) => item.departure_city_id == departureObject.departure_city_id), 1);
      console.log("isDummyArrivalData>>>>",this.isDummyArrivalData);
      this.manageDataService.SetArrivalArray(this.isDummyArrivalData);
    }else{
      this.apiService.onArrivalFlightList({
        flight_type: this.isTripValue,
        airport_code: departureObject.airport_code,
        airport_id: departureObject.airport_id,
        airport_name: departureObject.airport_name,
        departure_airport_id: departureObject.departure_airport_id,
        departure_city_code: departureObject.departure_city_code,
        departure_city_id: departureObject.departure_city_id,
        departure_city_name: departureObject.departure_city_name,
        shared_inventory_plan_Id: departureObject.shared_inventory_plan_Id,
        vendor_share_plan: departureObject.vendor_share_plan,
      });
    }
    this.manageDataService.SetDepartureSelectBox(false);
  }
  onDepartureSearchFilter(event:any){
    var eventValue = event.target.value.toLowerCase();
    this.departureArrayResponse=JSON.parse(this.departureArrayAllData).filter((item:any) => (item.departure_city_name.toLocaleLowerCase().includes(eventValue)) || (item.departure_city_code.toLocaleLowerCase().includes(eventValue)));
  }
  onSelectDepartureMaster(event: any): void {
    if(event){
      this.departureArrayResponseMaster.forEach((element:any) => Object.assign(element,{selected_status:0}));
      this.departureArrayResponse=this.departureArrayResponseMaster;
      var filterResult = [];
      filterResult = this.departureArrayResponse.filter((x: any) => x.departure_city_id == event);
      if (filterResult.length > 0) {
        filterResult[0].selected_status=1;
        var datasssss:any=this.departureArrayResponse;
        // console.log("datasssss===>>>",datasssss);
        this.isDummyDepartureData=datasssss[0];
        this.manageDataService.SetDepartureArray(this.isDummyDepartureData);
        this.manageDataService.SetArrivalArray(this.isDummyDepartureData);
        var bodyOjectData = {
          flight_type: this.isTripValue,
          airport_code: this.isDummyDepartureData.airport_code,
          airport_id: this.isDummyDepartureData.airport_id,
          airport_name: this.isDummyDepartureData.airport_name,
          departure_airport_id: this.isDummyDepartureData.departure_airport_id,
          departure_city_code: this.isDummyDepartureData.departure_city_code,
          departure_city_id: this.isDummyDepartureData.departure_city_id,
          departure_city_name: this.isDummyDepartureData.departure_city_name,
          shared_inventory_plan_Id: this.isDummyDepartureData.shared_inventory_plan_Id,
          vendor_share_plan: this.isDummyDepartureData.vendor_share_plan,
        }
        // console.log("bodyOjectData====",bodyOjectData);
        this.manageDataService.SetSelectedDepartureData(bodyOjectData);
        this.apiService.onArrivalFlightList(bodyOjectData);
      }
    }
  }
  
  public templateResult = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }
    return jQuery(state.text + '<br/><span style="font-size:11px;">' + state.additional.winner + '</span><span style="font-size:12px;float:right;margin-top:-16px;font-weight:500">' + state.additional.image + '</span>');
  }
  // function for selection template
  public templateSelection = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }
    return jQuery(state.text);
  }
}

export interface JQuery { }