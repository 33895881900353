import { Injectable } from '@angular/core';
import { ManagedataService } from './manage-data.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { SharedserviceService } from './sharedservice.service';
import {  HotelService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class ApiRequestService {
  constructor(public baseService: SharedserviceService, public manageDataService: ManagedataService,private ngxLoader: NgxUiLoaderService, public sharedService: HotelService, ) { }

  onDepartureFlightList(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/common/dep_city', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        if (data.data.length > 0) {
          data.data.sort((a,b) => a.departure_city_name.localeCompare(b.departure_city_name));
          data.data.forEach((element: any) => {
            Object.assign(element, { status: 1 });
          });
        }
        this.manageDataService.SetDepartureArray(data.data);
        this.ngxLoader.stop();
      } else {
        // this.messageService.showError(data.replyMsg);
        this.manageDataService.SetDepartureArray([]);

        this.ngxLoader.stop();
      }
    }, err => {

      this.ngxLoader.stop();
      // this.messageService.showError(err.error['replyMsg']);
      // console.log(`${ApiMethods.DEPARTURE_CITY_CUSTOM}`, err)
    })
  }
  onArrivalFlightList(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/common/arr_city', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        if (data.data.length > 0) {
          data.data.sort((a,b) => a.arrival_city_name.localeCompare(b.arrival_city_name));
          data.data.forEach((element: any) => {
            Object.assign(element, { status: 1 });
          });
        }
        this.manageDataService.SetArrivalArray(data.data);

        this.ngxLoader.stop();
      } else {
        // this.messageService.showError(data.replyMsg);
        this.manageDataService.SetArrivalArray([]);

        this.ngxLoader.stop();
      }
    }, err => {

      this.ngxLoader.stop();
      // this.messageService.showError(err.error['replyMsg']);
      // console.log(`${ApiMethods.ARRIVAL_CITY_CUSTOM}`, err)
    })
  }
  onDepartureFlightDateList(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/common/dates', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.manageDataService.SetDepartureDateArray(data.data);

        this.ngxLoader.stop();
      } else {
        // this.messageService.showError(data.replyMsg);
        this.manageDataService.SetDepartureDateArray([]);

        this.ngxLoader.stop();
      }
    }, err => {

      this.ngxLoader.stop();
      // this.messageService.showError(err.error['replyMsg']);
      // console.log(`${ApiMethods.DEPARTURE_DATE_CUSTOM}`, err)
    })
  }
  onArrivalFlightDateList(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/common/return_dates', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.manageDataService.SetReturnDateArray(data.data);

        this.ngxLoader.stop();
      } else {
        // this.messageService.showError(data.replyMsg);
        this.manageDataService.SetReturnDateArray([]);

        this.ngxLoader.stop();
      }
    }, err => {

      this.ngxLoader.stop();
      // this.messageService.showError(err.error['replyMsg']);
      // console.log(`${ApiMethods.RETURN_DATE_CUSTOM}`, err)
    })
  }
  onDepartureArrivalFlightList(payload: any) {
    this.ngxLoader.start();
    this.baseService.post('v1/city/list', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        var newArray: any = [];
        if (data.data.length > 0) {
          data.data.forEach((element: any) => {
            var obj: any = {
              airport_code: element.airport_code,
              airport_id: element.airport_id,
              airport_name: element.airport_name,
              departure_airport_id: element.airport_id,
              departure_city_code: element.city_code,
              departure_city_id: element.city_id,
              departure_city_name: element.city_name,
              arrival_airport_id: element.airport_id,
              arrival_city_code: element.city_code,
              arrival_city_id: element.city_id,
              arrival_city_name: element.city_name,
              status: element.status,
            };
            newArray.push(obj);
          });
        } else {
          newArray = [];
        }
        setTimeout(() => {
          localStorage.setItem('regularCityResponse', JSON.stringify(newArray))
          var cityData: any = localStorage.getItem('regularCityResponse')
          this.manageDataService.SetRegularCityArray(JSON.parse(cityData));
          // this.manageDataService.SetRegularCityArray(newArray);
        }, 1000);

        this.ngxLoader.stop();
      } else {
        // this.messageService.showError(data.replyMsg);

        this.ngxLoader.stop();
      }
    }, err => {

      this.ngxLoader.stop();
      // this.messageService.showError(err.error['replyMsg']);
      // console.log(`${ApiMethods.DEPARTURE_ARRIVAL_CITY_REGULAR}`, err)
    })
  }
  onCustomFlightSearch(payload: any) {
    this.ngxLoader.start();
    // this.manageDataService.SetSearchingFlightLoaderStatus(true);
    this.baseService.post('v1/common/search', payload).subscribe((data: any) => {
      if (data.replyCode == "success") {
        this.manageDataService.SetSearchFlightResponse(data);
        this.manageDataService.SetFlightImagePath(data.imgBasePath);
        this.ngxLoader.stop();
      } else {
        this.manageDataService.SetSearchFlightResponse([]);
        this.manageDataService.SetFlightImagePath('');
        this.ngxLoader.stop();
      }
    }, err => {
      this.ngxLoader.stop();
    })
  }
  // onRegularFlightSearch(payload: any) {
  //   this.ngxLoader.start();
  //   // this.manageDataService.SetSearchingFlightLoaderStatus(true);
  //   var airlinesArray: any = [];
  //   this.baseService.post('v1/fit/airline_list', payload).subscribe((data: any) => {
  //     if (data.replyCode == "success") {
  //       if (data.data.length > 0) {
  //         var sendRequestData: any = payload;
  //         setTimeout(() => {
  //           data.data.forEach((element: any) => {
  //             sendRequestData.prefered_airline = element;
  //             this.baseService.post('v1/fit/search', sendRequestData).subscribe((data: any) => {
  //               airlinesArray = airlinesArray.concat(data.data);
  //               this.manageDataService.SetSearchFlightResponse({ data: airlinesArray });
  //               this.manageDataService.SetFlightImagePath(data.imgBasePath);

  //               this.ngxLoader.stop();
  //               // this.manageDataService.SetSearchingFlightLoaderStatus(false);
  //             });
  //           });
  //         }, 1000);
  //       } else {
  //         airlinesArray = [];
  //         this.manageDataService.SetSearchFlightResponse({ data_oneway: airlinesArray });
  //         this.manageDataService.SetFlightImagePath(data.imgBasePath);

  //         this.ngxLoader.stop();
  //         // this.manageDataService.SetSearchingFlightLoaderStatus(false);
  //       }
  //     } else {
  //       airlinesArray = [];
  //       this.manageDataService.SetSearchFlightResponse({ data_oneway: airlinesArray });
  //       this.manageDataService.SetFlightImagePath(data.imgBasePath);

  //       this.ngxLoader.stop();
  //       // this.manageDataService.SetSearchingFlightLoaderStatus(false);
  //     }
  //   }, err => {

  //     this.ngxLoader.stop();
  //     // this.manageDataService.SetSearchingFlightLoaderStatus(false);
  //     // this.messageService.showError(err.error['replyMsg']);
  //     // console.log(`${ApiMethods.SEARCH_FLIGHT_REGULAR}`, err)
  //   })
  // }
  onRegularFlightSearch(payload: any) {
    this.ngxLoader.start();
    var airlinesArray: any = [];
    var sendRequestData: any = payload;
    // sendRequestData.prefered_airline = element;
    this.baseService.post('v1/fit/search', sendRequestData).subscribe((data: any) => {
        if (data.replyCode == "success") {
          if (data.data.length > 0) {
              airlinesArray = airlinesArray.concat(data.data);
              this.manageDataService.SetSearchFlightResponse({ data: airlinesArray });
              this.manageDataService.SetFlightImagePath(data.imgBasePath);
              this.ngxLoader.stop();
          }
        } else {
          airlinesArray = [];
          this.manageDataService.SetSearchFlightResponse({ data_oneway: airlinesArray });
          this.manageDataService.SetFlightImagePath(data.imgBasePath);
          this.ngxLoader.stop();
          // this.manageDataService.SetSearchingFlightLoaderStatus(false);
        }
        this.ngxLoader.stop();
        // this.manageDataService.SetSearchingFlightLoaderStatus(false);
    }, err => {
      this.ngxLoader.stop();
      // this.manageDataService.SetSearchingFlightLoaderStatus(false);
      // this.messageService.showError(err.error['replyMsg']);
      // console.log(`${ApiMethods.SEARCH_FLIGHT_REGULAR}`, err)
    })
  }



  
  onFoodCategoryList(payload: any) {
    this.ngxLoader.start();
    var foodCategoryList: any = {};
    var sendRequestData: any = payload;
    this.sharedService.post('food_categories', sendRequestData).subscribe((data: any) => {
        if (data.replyCode == "success") {
          if (data.data.length > 0) {              
              foodCategoryList = data.data;
              this.manageDataService.SetFoodCategoryList(foodCategoryList);
              this.ngxLoader.stop();
          }
        }
        this.ngxLoader.stop();
    }, err => {
      this.ngxLoader.stop();
    })
  }

  onRestaurantList(payload: any) {
    this.ngxLoader.start();
    var restaurantsList: any = {};
    var sendRequestData: any = payload;
    this.sharedService.post('restaurants', sendRequestData).subscribe((data: any) => {
        if (data.replyCode == "success") {
          if (data.data.length > 0) {              
              restaurantsList = data.data;
              this.manageDataService.SetRestaurantsList(restaurantsList);
              this.ngxLoader.stop();
          }
        }
        this.ngxLoader.stop();
    }, err => {
      this.ngxLoader.stop();
    })
  }


  onRestaurantChargesList(payload: any) {
    this.ngxLoader.start();
    var restaurantsChargesList: any = {};
    var sendRequestData: any = payload;
    this.sharedService.post('restaurants_charges', sendRequestData).subscribe((data: any) => {
        if (data.replyCode == "success") {
          if (data.data.length > 0) {              
            restaurantsChargesList = data.data;
              this.manageDataService.SetRestaurantsChargesList(restaurantsChargesList);
              this.ngxLoader.stop();
          }
        }
        this.ngxLoader.stop();
    }, err => {
      this.ngxLoader.stop();
    })
  }
  


}
