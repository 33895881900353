import { AuthGuardService, HomeGuardService } from './services';
import { Component, NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AdminmasterComponent } from './adminlayout/adminmaster/adminmaster.component';
import { DashmasterComponent } from './dashboardlayout/dashmaster/dashmaster.component';
import { LayoutmasterComponent } from './layout/layoutmaster/layoutmaster.component';
import { SpeechSearchComponent } from './speech-search/speech-search.component';
 
const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },{
    path: 'auth',
    component: LayoutmasterComponent,
    children: [
      { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
    ]
  },{
    path: '',
    component: DashmasterComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./homedashboard/homedashboard.module').then(m => m.HomedashboardModule)
      },{
        path: 'master',
        loadChildren: () => import('./route-file/master.module').then(m => m.MasterModule)
      },{
        path: 'booking-flight',
        loadChildren: () => import('./booking-flight/booking-flight.component.module').then(m => m.BookingFLightModule)
      },{
        path: 'inventory',
        loadChildren: () => import('./module/inventory.module').then(m => m.InventoryModule)
      },{
        path: 'myapi',
        loadChildren: () => import('./module/myapi.module').then(m => m.MyApiModule)
      },{
        path: 'dashboard1',
        loadChildren: () => import('./module/dashboard.module').then(m => m.DashboardModule)
      },{
        path: 'partner',
        loadChildren: () => import('./module/partner-api.module').then(m => m.PartnerApiModule)
      },{
        path: 'supplier',
        loadChildren: () => import('./module/supplier.module').then(m => m.SupplierModule)
      },{
        path: 'booking-summary',
        loadChildren: () => import('./module/book-summary.module').then(m => m.BookSummaryModule)
      },{
        path: 'booking-cancel',
        loadChildren: () => import('./booking-cancel/booking-cancel.module').then(m => m.BookingFlightCancelModule)
      },{
        path: 'speech-search',
        component:SpeechSearchComponent,
      },{
        path: 'hotel',
        children:[
          { path: '', loadChildren: () => import('./hotels/hotel.module').then(m => m.HotelModule) },
          { path: 'inventory', loadChildren: () => import('./hotels/hotel-inventory.module').then(m => m.HotelInventoryModule) },
          { path: 'facilities', loadChildren: () => import('./hotels/hotel-facilities.module').then(m => m.HotelFacilitiesModule) },
          { path: 'room-facilities', loadChildren: () => import('./hotels/room-facilities.module').then(m => m.HotelRoomFacilitiesModule) },
          { path: 'room', loadChildren: () => import('./hotels/room-inventory.module').then(m => m.RoomInventoryModule) },
          { path: 'email-template', loadChildren: () => import('./hotels/email-template.module').then(m => m.HotelEmailTemplateModule) },
          { path: 'combo', loadChildren: () => import('./hotels/hotel-combo.module').then(m => m.HotelComboModule) },
          { path: 'margin', loadChildren: () => import('./hotels/hotel-margin.module').then(m => m.HotelMarginModule) },
          { path: 'user-margin', loadChildren: () => import('./hotels/user-margin.module').then(m => m.UserMarginModule) },
        ]
      },{
        path: 'images',
        loadChildren: () => import('./hotels/hotel-room-images.module').then(m => m.HotelRoomImagesModule)
      },{
        path: 'hotel-vendor',
        children:[
          { path: '', loadChildren: () => import('./vendors/vendor.module').then(m => m.VendorModule) },
        ]
      }
    ]
  },{
    path: '',
    component:DashmasterComponent,
    children:[
      {
      path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    },
    ]
  },{
      path: '',
      component:LayoutmasterComponent,
      children:[
        { path: 'homepage', loadChildren: () => import('./shome/shome.module').then(m => m.ShomeModule) },
      ]
  },{
      path: 'clientdashboard',
      component:LayoutmasterComponent,
      canActivate: [AuthGuardService],
      children:[
        { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
        { path: '', loadChildren: () => import('./shome/shome.module').then(m => m.ShomeModule) },
      ]
    },{
    path: '',
    component:DashmasterComponent,
    canActivate: [AuthGuardService],
    children:[
      {
        path: '',
        loadChildren: () => import('./route-file/route-file.module').then(m => m.RouteConfiqFileModule)
      },
    ]
  },{
    path: '', component:AdminmasterComponent,
    canActivate: [AuthGuardService],
    children:[
      {
        path: '',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
