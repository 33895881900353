import * as ReStopListAction from './action';
import { ReStopListAdapter, orderInitialState, ReStopListState } from './state';

export function ReStopListReducer(state: ReStopListState = orderInitialState, action: ReStopListAction.Actions) {
  switch (action.type) {
    case ReStopListAction.ADD_ReStopList:
      return {
        ...ReStopListAdapter.addOne(action.payload, state)
      };
    case ReStopListAction.REMOVE_ReStopList:
      return {
        ...ReStopListAdapter.removeAll(state),
        isLoading: false,
        error: null
      };
    case ReStopListAction.UPDATE_ReStopList:

      return {
        ...ReStopListAdapter.updateOne({ id: action.payload['id'], changes: action.payload }, state)
      };
    case ReStopListAction.POST_ReStopList:
      return {
        ...ReStopListAdapter.removeAll(state),
        isLoading: false,
        error: null
      };
    default:
      return state;
  }
}
