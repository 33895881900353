import * as ClubAction from './action';
import { ClubAdapter, orderInitialState, ClubState } from './state';

export function ClubReducer(state: ClubState = orderInitialState, action: ClubAction.Actions) {
  switch (action.type) {
    case ClubAction.ADD_Club:
      return {
        ...ClubAdapter.addOne(action.payload, state)
      };
    case ClubAction.REMOVE_Club:
      return {
        ...ClubAdapter.removeAll(state),
        isLoading: false,
        error: null
      };
    case ClubAction.UPDATE_Club:

      return {
        ...ClubAdapter.updateOne({ id: action.payload['id'], changes: action.payload }, state)
      };
    case ClubAction.POST_Club:
      return {
        ...ClubAdapter.removeAll(state),
        isLoading: false,
        error: null
      };
    default:
      return state;
  }
}
