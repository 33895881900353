import * as StopListAction from './action';
import { StopListAdapter, orderInitialState, StopListState } from './state';

export function StopListReducer(state: StopListState = orderInitialState, action: StopListAction.Actions) {
  switch (action.type) {
    case StopListAction.ADD_StopList:
      return {
        ...StopListAdapter.addOne(action.payload, state)
      };
    case StopListAction.REMOVE_StopList:
      return {
        ...StopListAdapter.removeAll(state),
        isLoading: false,
        error: null
      };
    case StopListAction.UPDATE_StopList:

      return {
        ...StopListAdapter.updateOne({ id: action.payload['id'], changes: action.payload }, state)
      };
    case StopListAction.POST_StopList:
      return {
        ...StopListAdapter.removeAll(state),
        isLoading: false,
        error: null
      };
    default:
      return state;
  }
}
