import { Component, OnInit } from '@angular/core';
import { AudioConfig, ResultReason, SpeechConfig, SpeechRecognizer } from 'microsoft-cognitiveservices-speech-sdk';

declare var Tesseract;

@Component({
  selector: 'app-speech-search',
  templateUrl: './speech-search.component.html',
  styleUrls: ['./speech-search.component.scss']
})

export class SpeechSearchComponent implements OnInit {
  Result = 'Recognizing...';
  ngOnInit(): void {
    this.test();    
   // throw new Error('Method not implemented.');
  }
  

  name = 'Speech To Text';
  private recognizing = false;
  private notification: string;
  private innerHtml: string = '';
  private lastRecognized: string = '';
  _recognizer: SpeechRecognizer;
  public finalss:any = '';
  startButton(event) {
    if (this.recognizing) {
      this.stop();
      this.recognizing = false;
    }
    else {

      this.recognizing = true;
      console.log("record");

      //const { webkitSpeechRecognition }: IWindow = <IWindow>window;
      const audioConfig = AudioConfig.fromDefaultMicrophoneInput();

      const speechConfig = SpeechConfig.fromSubscription("9f27cbd7f2a64d1eb2de1e3fab33c1c5", "centralindia");
      speechConfig.speechRecognitionLanguage = 'en-US';
      speechConfig.enableDictation();
      this._recognizer = new SpeechRecognizer(speechConfig, audioConfig)
      this._recognizer.recognizing = this._recognizer.recognized = this.recognizerCallback.bind(this)
      this._recognizer.startContinuousRecognitionAsync();
    }
  }
  recognizerCallback(s, e) {
    console.log(e.result.text);
    const reason = ResultReason[e.result.reason];
    console.log(reason);
    if (reason == "RecognizingSpeech") {
      this.innerHtml = this.lastRecognized + e.result.text;
    }
    if (reason == "RecognizedSpeech") {
      this.lastRecognized += e.result.text + "\r\n";
      this.innerHtml = this.lastRecognized;
    }
  }
  stop() {

    this._recognizer.stopContinuousRecognitionAsync(

      stopRecognizer.bind(this),

      function (err) {

        stopRecognizer.bind(this)

        console.error(err)

      }.bind(this)

    )



    function stopRecognizer() {

      this._recognizer.close()

      this._recognizer = undefined

      console.log('stopped')

    }

  }

  test(){
    this.finalss = '';
    Tesseract.recognize('assets/images/email-4.jpg').then(function(result){  
    //  return result.text;
      //this.finalss =  result.text;    
      console.log(result.text);      
      }); 
     // console.log(this.finalss)   
  }  

 
}
