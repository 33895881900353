import { Component, ElementRef, HostListener, OnInit } from '@angular/core';

import { ApiRequestService } from 'src/app/services/api.service';
import { DatePipe } from '@angular/common';
import { ManagedataService } from 'src/app/services/manage-data.service';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
declare var jQuery: any;

@Component({
  selector: 'app-custom-arrival-select-box',
  templateUrl: './custom-arrival-select-box.component.html',
  styleUrls: ['./custom-arrival-select-box.component.scss'],
  providers: [DatePipe]
})
export class CustomArrivalSelectBoxComponent implements OnInit{
  public text: String;
  public options2: Options;
  isArrivalSelectBoxFlag:boolean=false;
  public isMasterOptions: any = '1';
  departureDataMaster:any=[];
  public arrivalData: any=[]; public arrivalArray: any = [];arrivalArrayAllData: any = []; arrivalArrayResponse: any = []; SelectedArrivalData: any = {};
  public isTripValue: any = 'one_way';public SelectedDepartureData:any={};
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.eRef.nativeElement.contains(event.target)) {
      this.text = "clicked inside";
    } else {
      this.manageDataService.SetArrivalSelectBox(false);
      this.text = "clicked outside";
    }
  }
  constructor(private eRef: ElementRef,public manageDataService:ManagedataService,public apiService: ApiRequestService, public datePipe: DatePipe){
  }
  ngOnInit(): void {
    this.manageDataService.GetArrivalSelectBox().subscribe((flag:boolean)=> this.isArrivalSelectBoxFlag=flag);

    this.manageDataService.GetTripValue().subscribe((data: any) => {
      if (data) {
        this.isTripValue = data;
      }
    })
    this.manageDataService.GetArrivalArray().subscribe((data: any) => {
      this.arrivalArrayResponse = [];
      if (data.length > 0) {
        this.arrivalArrayResponse = data;
        if(this.isMasterOptions=='1'){
          this.arrivalArrayResponse.forEach((element: any) => {
            var object:any={};
            object.id=element.arrival_city_id;
            object.text='<span style="font-size:16px">' + element.arrival_city_name + '<p style="display:none;">' + element.arrival_city_code + '</p></span>',
            object.additional={
              image: '<span style="font-weight:500">(' + element.arrival_city_code + ')</span>',
              winner: '<span style="font-weight:500;width:100%;float:lef">' + element.airport_name + '</span>'
            }
            // var object: any = {
            //   id: element.arrival_city_id,
            //   text: element.arrival_city_name + ' (' + element.arrival_city_code + ')',
            //   status: element.status
            // };
            this.arrivalArray.push(object);
            this.arrivalData = this.arrivalArray;
          });
          this.options2 = {
            templateResult: this.templateResult,
            templateSelection: this.templateSelection
          };
        }
      }
    })
    this.manageDataService.GetDepartureArray().subscribe((data:any)=>{
      if(Object.keys(data).length>0){
        // console.log("departureDataMaster======>>>>>",data);          
        this.departureDataMaster = data;
      }
    })
    this.manageDataService.GetSelectedDepartureData().subscribe((data: any) => {
      // console.log("SelectedDepartureData=========>>",data);        
      if (Object.keys(data).length > 0) {
        this.SelectedDepartureData = data;
      }else{
        this.SelectedDepartureData = {};
      }
    })
    this.manageDataService.GetSelectedArrivalData().subscribe((data: any) => {
      if (Object.keys(data).length > 0) {
        this.SelectedArrivalData = data;
      }else{
        this.SelectedArrivalData = {};
      }
    })
    this.manageDataService.GetMasterTripOptions().subscribe((data: any) => {
      if (data) {
        this.isMasterOptions = data;
      }
    })
  }
  onOpenCloseArrival(){
    this.isArrivalSelectBoxFlag=!this.isArrivalSelectBoxFlag;
    this.manageDataService.SetArrivalSelectBox(this.isArrivalSelectBoxFlag);
  }
  onArrivalSearchFilter(event:any){
    // console.log("arrivalArrayAllData==============>>>>88",this.arrivalArrayResponse);
    var eventValue = event.target.value.toLowerCase();
    this.arrivalArrayResponse=this.arrivalArrayResponse.filter((item:any) => (item.arrival_city_name.toLocaleLowerCase().includes(eventValue)) || (item.arrival_city_code.toLocaleLowerCase().includes(eventValue)));
  }
  onSelectArrivalCity(arrivalObject:any){
    this.manageDataService.SetSelectedArrivalData(arrivalObject);
    if (this.isMasterOptions == 2) {
      var validdates: any = [];
      var result: any = [];
      for (var i = 0; i < 100; i++) {
        var d = new Date();
        d.setDate(d.getDate() + i);
        const newDate: any = this.datePipe.transform(new Date(d), 'MM/dd/yyyy');
        result.push({ flight_date: this.datePipe.transform(new Date(d), 'yyyy-MM-dd') })
        validdates.push(new Date(newDate));
      }
      // console.log("validdates>>>>>>",result);
      this.manageDataService.SetDepartureDateArray(result);
    }else{
      this.apiService.onDepartureFlightDateList({
        "departure_city_code": this.SelectedDepartureData.departure_city_code,
        "arrival_city_code": this.SelectedArrivalData.arrival_city_code,
        "departure_city_id": this.SelectedDepartureData.departure_city_id,
        "arrival_city_id": this.SelectedArrivalData.arrival_city_id,
        is_return: this.isTripValue == "one_way" ? 0 : 1,
      });
    }
    this.manageDataService.SetArrivalSelectBox(false);
  }
  onSelectArrival(event: any) {
    var filterResult = [];
    if (event) {
      filterResult = this.arrivalArrayResponse.filter((x: any) => x.arrival_city_id == event);
      this.manageDataService.SetSelectedArrivalData(filterResult[0]);
      if (filterResult.length > 0) {
      // console.log("filterResult====>>>>",filterResult);
      var departureDateFlightSendData={
        "departure_city_code": this.departureDataMaster.departure_city_code,
        "arrival_city_code": filterResult[0].arrival_city_code,
        "departure_city_id": this.departureDataMaster.departure_city_id,
        "arrival_city_id": filterResult[0].arrival_city_id,
        is_return: this.isTripValue == "one_way" ? 0 : 1,
      }
      this.apiService.onDepartureFlightDateList(departureDateFlightSendData);
      }
    }
  }
  onArrivalFilterChange(event: any) {
    this.arrivalData = []; this.arrivalArray = [];
    // console.log("arrivalArrayResponse===========>>>",this.arrivalArrayResponse);
    this.arrivalArrayResponse.forEach((element: any) => {
      var object: any = {
        id: element.arrival_city_id,
        text: element.arrival_city_name + ' (' + element.arrival_city_code + ')',
        status: element.status
      };
      this.arrivalArray.push(object);
      this.arrivalData = this.arrivalArray;
    });
  }

  public templateResult = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }
    return jQuery(state.text + '<br/><span style="font-size:11px;">' + state.additional.winner + '</span><span style="font-size:12px;float:right;margin-top:-16px;font-weight:500">' + state.additional.image + '</span>');
  }
  // function for selection template
  public templateSelection = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }
    return jQuery(state.text);
  }
}

export interface JQuery { }