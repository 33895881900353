import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ManagedataService {

  isAirlineArray = new BehaviorSubject<any>([]);
  GetAirlineArray() { return this.isAirlineArray.asObservable() }
  SetAirlineArray(value: any) { this.isAirlineArray.next(value) }

  isCityArray = new BehaviorSubject<any>([]);
  GetCityArray() { return this.isCityArray.asObservable() }
  SetCityArray(value: any) { this.isCityArray.next(value) }

  isLoggedInUserDetails = new BehaviorSubject<any>({});
  GetLoggedInUserDetails() { return this.isLoggedInUserDetails.asObservable() }
  SetLoggedInUserDetails(value: any) { this.isLoggedInUserDetails.next(value) }

  isLoggedInUserImage = new BehaviorSubject<string>('');
  GetLoggedInUserImage() { return this.isLoggedInUserImage.asObservable() }
  SetLoggedInUserImage(value: any) { this.isLoggedInUserImage.next(value) }

  isLoggedInUserImageUrl = new BehaviorSubject<string>('');
  GetLoggedInUserImageUrl() { return this.isLoggedInUserImageUrl.asObservable() }
  SetLoggedInUserImageUrl(value: any) { this.isLoggedInUserImageUrl.next(value) }

  isLoggedInUserStatus = new BehaviorSubject<boolean>(false);
  GetLoggedInUserStatus() { return this.isLoggedInUserStatus.asObservable() }
  SetLoggedInUserStatus(value: boolean) { this.isLoggedInUserStatus.next(value) }


  isInventoryViewType = new BehaviorSubject<string>('');
  GetInventoryViewType() { return this.isInventoryViewType.asObservable() }
  SetInventoryViewType(value: any) { this.isInventoryViewType.next(value) }

  isInventoryCalenderData = new BehaviorSubject<any>({});
  GetInventoryCalenderData() { return this.isInventoryCalenderData.asObservable() }
  SetInventoryCalenderData(value: any) { this.isInventoryCalenderData.next(value) }

  isMasterApiChanges = new BehaviorSubject<boolean>(false);
  GetMasterApiChanges() { return this.isMasterApiChanges.asObservable() }
  SetMasterApiChanges(value: any) { this.isMasterApiChanges.next(value) }

  isHotelTravellersInfo = new BehaviorSubject<any>({numberOfAdults:0,numberOfChild:0,start_date:'',end_date:''});
  GetHotelTravellersInfo() { return this.isHotelTravellersInfo.asObservable() }
  SetHotelTravellersInfo(value: any) { this.isHotelTravellersInfo.next(value) }


  isTripValue = new BehaviorSubject<string>("");  // Search Flights

  isDepartureArray = new BehaviorSubject<any>([]);
  isRegularCityArray = new BehaviorSubject<any>([]);
  isArrivalArray = new BehaviorSubject<any>([]);
  isDepartureDateArray = new BehaviorSubject<any>([]);
  isReturnDateArray = new BehaviorSubject<any>([]);
  isSelectedDepartureData = new BehaviorSubject<any>({});
  isSelectedArrivalData = new BehaviorSubject<any>({});
  isSelectedTravelersData = new BehaviorSubject<any>({});
  isFlightImagePath = new BehaviorSubject<string>("");
  GetTripValue() { return this.isTripValue.asObservable() }
  SetTripValue(value: any) { this.isTripValue.next(value) }

  GetDepartureArray() { return this.isDepartureArray.asObservable() }
  SetDepartureArray(value: any) { this.isDepartureArray.next(value) }

  GetRegularCityArray() { return this.isRegularCityArray.asObservable() }
  SetRegularCityArray(value: any) { this.isRegularCityArray.next(value) }

  GetArrivalArray() { return this.isArrivalArray.asObservable() }
  SetArrivalArray(value: any) { this.isArrivalArray.next(value) }

  GetDepartureDateArray() { return this.isDepartureDateArray.asObservable() }
  SetDepartureDateArray(value: any) { this.isDepartureDateArray.next(value) }

  GetReturnDateArray() { return this.isReturnDateArray.asObservable() }
  SetReturnDateArray(value: any) { this.isReturnDateArray.next(value) }

  GetSelectedDepartureData() { return this.isSelectedDepartureData.asObservable() }
  SetSelectedDepartureData(value: any) { this.isSelectedDepartureData.next(value) }

  GetSelectedArrivalData() { return this.isSelectedArrivalData.asObservable() }
  SetSelectedArrivalData(value: any) { this.isSelectedArrivalData.next(value) }

  GetSelectedTravelersData() { return this.isSelectedTravelersData.asObservable() }
  SetSelectedTravelersData(value: any) { this.isSelectedTravelersData.next(value) }

  isMasterTripOptions = new BehaviorSubject<number>(1);
  GetMasterTripOptions() { return this.isMasterTripOptions.asObservable() }
  SetMasterTripOptions(value: any) { this.isMasterTripOptions.next(value) }

  isSearchFlightResponse = new BehaviorSubject<any>({});
  GetSearchFlightResponse() { return this.isSearchFlightResponse.asObservable() }
  SetSearchFlightResponse(value: any) { this.isSearchFlightResponse.next(value) }

  isFormReset = new BehaviorSubject<boolean>(false);
  GetFormReset() { return this.isFormReset.asObservable() }
  SetFormReset(value: any) { this.isFormReset.next(value) }

  GetFlightImagePath() { return this.isFlightImagePath.asObservable() }
  SetFlightImagePath(value: any) { this.isFlightImagePath.next(value) }


  isDepartureSelectBox = new BehaviorSubject<boolean>(false);
  GetDepartureSelectBox() { return this.isDepartureSelectBox.asObservable() }
  SetDepartureSelectBox(value: any) { this.isDepartureSelectBox.next(value) }

  isArrivalSelectBox = new BehaviorSubject<boolean>(false);
  GetArrivalSelectBox() { return this.isArrivalSelectBox.asObservable() }
  SetArrivalSelectBox(value: any) { this.isArrivalSelectBox.next(value) }

  isDateNextPreviousOption = new BehaviorSubject<boolean>(false);
  GetDateNextPreviousOption() { return this.isDateNextPreviousOption.asObservable() }
  SetDateNextPreviousOption(value: any) { this.isDateNextPreviousOption.next(value) }

  isAvailableToDate = new BehaviorSubject<any>([]);
  GetAvailableToDate() { return this.isAvailableToDate.asObservable() }
  SetAvailableToDate(value: any) { this.isAvailableToDate.next(value) }

  isAvailableCurrentDate = new BehaviorSubject<any>("");
  GetAvailableCurrentDate() { return this.isAvailableCurrentDate.asObservable() }
  SetAvailableCurrentDate(value: any) { this.isAvailableCurrentDate.next(value) }



  isUserToken = new BehaviorSubject<string>('');
  GetUserToken() { return this.isUserToken.asObservable() }
  SetUserToken(value: any) { this.isUserToken.next(value) }

  
  isFoodCategoryList = new BehaviorSubject<any>([]);
  GetFoodCategoryList() { return this.isFoodCategoryList.asObservable() }
  SetFoodCategoryList(value: any) { this.isFoodCategoryList.next(value) }

  
  isRestaurantsList = new BehaviorSubject<any>([]);
  GetRestaurantsList() { return this.isRestaurantsList.asObservable() }
  SetRestaurantsList(value: any) { this.isRestaurantsList.next(value) }

  isRestaurantsChargesList = new BehaviorSubject<any>([]);
  GetRestaurantsChargesList() { return this.isRestaurantsChargesList.asObservable() }
  SetRestaurantsChargesList(value: any) { this.isRestaurantsChargesList.next(value) }

  
  isSetSelectedQuantity = new BehaviorSubject<any>({});
  GetSetSelectedQuantity() { return this.isSetSelectedQuantity.asObservable() }
  SetSetSelectedQuantity(value: any) { this.isSetSelectedQuantity.next(value) }
  
}
