import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { DashboardlayoutRoutingModule } from './dashboardlayout-routing.module';
import { DashmasterComponent } from './dashmaster/dashmaster.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MaterialModule } from '../route-file/material.module';
import { environment } from "../../environments/environment";

// import { initializeApp } from 'firebase/app';
// initializeApp(environment.firebase);



@NgModule({
  declarations: [HeaderComponent, FooterComponent, DashmasterComponent],
  imports: [
    CommonModule,
    DashboardlayoutRoutingModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatBadgeModule,
    MatMenuModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DashboardlayoutModule {

}
