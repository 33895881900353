import * as PnrAction from './action';
import { PnrAdapter, orderInitialState, PnrState } from './state';

export function PnrReducer(state: PnrState = orderInitialState, action: PnrAction.Actions) {
  switch (action.type) {
    case PnrAction.ADD_Pnr:
      let data: any = action.payload;
      data.forEach(element => {
        var obj = element;
        Object.assign(obj, { date: new Date(element.Date) });
      });
      return {
        ...PnrAdapter.addOne(data, state)
      };
    case PnrAction.REMOVE_Pnr:
      return {
        ...PnrAdapter.removeAll(state),
        isLoading: false,
        error: null
      };
    case PnrAction.UPDATE_Pnr:
      return {
        ...PnrAdapter.updateOne({ id: action.payload['id'], changes: action.payload }, state)
      };
    case PnrAction.POST_Pnr:
      return {
        ...PnrAdapter.removeAll(state),
        isLoading: false,
        error: null
      };
    default:
      return state;
  }
}
