import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class HotelService {
  public auth_token: any;
  public imageBase: any;
  constructor(private router: Router, private http: HttpClient) {
    this.auth_token = JSON.parse(localStorage.getItem("token"));
    this.imageBase = 'https://fd1.fareboutique.com/images/'
  }

  get(url: any): Observable<any> {
    var headers: HttpHeaders;
    if (this.auth_token != undefined)
      headers = new HttpHeaders({ 'Authorization': "Bearer " + this.auth_token, 'Content-Type': 'application/json','x-api-key':environment.firebase.apiKey });
    return this.http.get(environment.hotel_booking_url + url, {
      headers: headers
    });
  }

  post(url: any, data: any): Observable<any> {
    if (this.auth_token) {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json','x-api-key':environment.firebase.apiKey });
      return this.http.post(environment.hotel_booking_url + url, data, {
        headers: headers
      });
    } else {
      let headers = new HttpHeaders({ 'Content-Type': 'application/json','x-api-key':environment.firebase.apiKey });
      return this.http.post(environment.hotel_booking_url + url, data, {
        headers: headers
      });
    }
  }
  postFile(fileToUpload: File) {
    const formData: FormData = new FormData();
    formData.append('attachment', fileToUpload, fileToUpload.name);
    return this.http.post(environment.hotel_booking_url + 'upload_file', formData)
  }
}
